<template>
  <div style="width: 100%;display:flex;justify-content: center;align-items: center">
    <div style="border: 1px solid #42A5F5;width: 40px;height: 0;margin-right: 10px"></div>
    <span style="font-size: 12px;color: #999999">{{name}}</span>
  </div>
</template>

<script>
export default {
  name: "legend",
  props: {
    name: String
  }
}
</script>

<style scoped>

</style>