<template>
  <div class="page">
    <div class="card">
      <p style="width: 100%;text-align: center">诉求数量</p>
      <Legend name="诉求数量" />
      <Chart type="line" :options="{legend: {display:false}}" :data="demandsNumber"/>
    </div>
  </div>
</template>

<script>
import Legend from "@/views/platform/dataDiagram/legend";
export default {
  name: "demandsData",
  components: {Legend},
  data() {
    return {
      demandsNumber: null
    }
  },
  mounted() {
    this.getDate()
  },
  methods: {
    changeDate(name) {
      this.tableName = name
      this.getDate()
    },
    getDate() {
      this.$http.get('/Statistics/demands').then((res) => {
        console.log(res.data)
        let demandsNumber = res.data.demandsNumber
        let labels = []
        let data = []

        for (let i = 0; i < demandsNumber.length; i++) {
          labels.push(demandsNumber[i].months)
          data.push(demandsNumber[i].count)
        }
        this.demandsNumber = {
          labels: labels,
          datasets: [
            {
              label: '诉求数量',
              fill: false,
              borderColor: '#42A5F5',
              data: data
            }
          ]
        }
      })
    }
  }
}
</script>

<style scoped>

</style>